//
// map.js
// Theme module
//

const maps = document.querySelectorAll('[data-map]');
const accessToken = 'pk.eyJ1IjoibWZhbW9zIiwiYSI6ImNsYzBvcmc2ejBzdDUzd3A5aXZvbDhzYWwifQ.AQQJQU_1GaCx0G7ki06jag';

maps.forEach((map) => {
  const elementOptions = map.dataset.map ? JSON.parse(map.dataset.map) : {};

  const defaultOptions = {
    container: map,
    style: 'mapbox://styles/mapbox/light-v9',
    scrollZoom: false,
    interactive: true,
  };

  const options = {
    ...defaultOptions,
    ...elementOptions,
  };

  // Get access token
  mapboxgl.accessToken = accessToken;

  // Init map
  const output = new mapboxgl.Map(options);

  const marker1 = new mapboxgl.Marker()
    .setLngLat(elementOptions.center)
    .addTo(output);
});
